/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.player-wrapper {
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.soundWave__wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  height: 70px;
}

.soundWave__wrapper .stroke {
  height: 100%;
  width: 10px;
  background-color: #7367f0;
  border-radius: 10px;
  animation-name: soundAnimation;
  animation-duration: 1.2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.stroke:nth-child(1) {
  animation-delay: 0s;
}
.stroke:nth-child(2) {
  animation-delay: 0.3s;
}
.stroke:nth-child(3) {
  animation-delay: 0.6s;
}
.stroke:nth-child(4) {
  animation-delay: 0.9s;
}

.overlay__player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
}
.overlay__player.translate {
  top: -25px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes soundAnimation {
  50% {
    height: 20%;
  }
  100% {
    height: 100%;
  }
}

// Deck
.deck__wrapper {
  aspect-ratio: 392 / 472;
  height: 252px;
  position: relative;
  .stack__fake {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    img.deck__img__stack {
      width: 100%;
      height: 100%;
      object-fit: cover; // because widht and height match with fake img
    }
  }
  .deck__image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    border: 1px solid #d9d9d9;
    transform: translate(-50%, -50%);
    z-index: 2;
    background-color: #fff;
    border-radius: 10px;
    img.deck__img__content {
      width: 93%;
      height: 93%;
      // transform: rotate(15deg);
      border-radius: 5px;
    }
  }
}
.folder__wrapper {
  aspect-ratio: 392 / 472;
  height: 252px;
  img.folder--icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.scene--card {
  width: 100%;
  aspect-ratio: 3/4;
  margin: 40px 0;
  perspective: 600px;
}

.card__rotate {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transform-style: preserve-3d;
  transform-origin: center right;
  transition: transform 1s;
}

.card__rotate.is-flipped {
  transform: translateX(-100%) rotateY(-180deg);
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 260px;
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  backface-visibility: hidden;
  border-radius: 10px;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.1));
}

.card__face--front {
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  .card__front__image {
    width: 70%;
    min-height: 70%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
    img {
      width: 100%;
    }
  }
  .card__front__text {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    font-size: 18px;
    p {
      margin-bottom: 0;
    }
    img {
      height: 24px;
      width: 24px;
    }
  }
}

.card__face--back {
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
  transform: rotateY(180deg);
  .card__back__image {
    width: 70%;
    min-height: 70%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
    img {
      width: 100%;
    }
  }
  .card__back__text {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    font-size: 18px;
    p {
      margin-bottom: 0;
    }
    img {
      height: 24px;
      width: 24px;
    }
  }
}
// ==== Draw Game ====

.draw__game {
  display: flex;
  justify-content: center;
  .canvas {
    // border: 1px solid black;
    box-shadow: 0 4px 24px 0 rgba(#22292f, 0.1);
    width: 70%;
    position: relative;
    .image-canva {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .draw__pointer {
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: goldenrod;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      z-index: 3;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &__btn-close {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(25%, -25%);
        opacity: 0;
        transition: opacity 0.35s;
      }
      &:hover .draw__pointer__btn-close {
        opacity: 1;
      }
    }
    .overlay__pointer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
}
.fallback-spinner.app-loader .fallback-logo {
  animation-duration: 3s;
  animation-name: scalingLoading;
  animation-iteration-count: infinite;
}
@keyframes scalingLoading {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
